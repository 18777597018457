import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Hi.`}</h3>
    <p><inlineCode parentName="p">{`An Open Plan`}</inlineCode>{` is a project inspired by
`}<a parentName="p" {...{
        "href": "https://twitter.com/simonsarris/status/1260685417109696512?s=20"
      }}>{`a series of tweets`}</a>{`, Andy Matuschak's note on `}<a parentName="p" {...{
        "href": "https://notes.andymatuschak.org/z21cgR9K3UcQ5a7yPsj2RUim3oM2TzdBByZu"
      }}>{`working with the garage door up`}</a>{`, John Carmack's `}<a parentName="p" {...{
        "href": "https://garbagecollected.org/2017/10/24/the-carmack-plan/"
      }}>{`.plan pages`}</a>{`
and Derek Siver's `}<a parentName="p" {...{
        "href": "http://nownownow.com"
      }}>{`now page`}</a></p>
    <p>{`If you'd like to add yourself, please check out the about page. The idea is
to create a page on the web (for example under `}<inlineCode parentName="p">{`/plan`}</inlineCode>{` on your personal website)
, and post updates on your work there, as you're doing it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      